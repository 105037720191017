<template>
  <mobile-screen :header="true" screen-class="save-preset gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="search-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("search", "presets", "save-preset") }}
        </div>
        <template v-slot:right>
          <button @click="savePreset">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="presetName"
              >{{ displayLabelName("search", "presets", "preset-name") }}
            </label>
            <input
              as="input"
              type="text"
              id="presetName"
              v-model="presetName"
            />
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "SavePreset",
  data() {
    return {
      presetName: null
    };
  },
  computed: {
    ...mapState("searchFilters", ["filters", "filterPage", "filterSection"]),
    sections() {
      let newSections = {};
      this.filters.forEach(filter => {
        if (newSections[filter.section]) {
          newSections[filter.section].push(filter);
        } else {
          newSections[filter.section] = [filter];
        }
      });
      return newSections;
    },
    selectedFilters() {
      return this.filters.filter(el => el.selected);
    }
  },
  methods: {
    ...mapActions("count", ["getFilters"]),
    ...mapActions("occupancy", ["getOccupancyFilters"]),
    savePreset() {
      if (!this.presetName) {
        return;
      }
      let values = {
        preset_name: this.presetName
      };

      this.$store.commit("loader/setScreenLoading", true, { root: true });

      if (this.filterPage === "count") {
        let levelIds = [];
        let resourceTypeIds = [];
        let resourceIds = [];
        this.selectedFilters.forEach(el => {
          switch (el.slug) {
            case "locations":
              levelIds.push(el.id);
              break;
            case "types":
              resourceTypeIds.push(el.id);
              break;
            case "resources":
              resourceIds.push(el.id);
              break;
            case "capacity":
              if (el.value) {
                values.capacity = el.value;
              }
              break;
            case "dates":
              values.period_id = el.id;
              if (el.id === 1) {
                values.date_start = el.date_start;
                values.date_end = el.date_end;
              }
              break;
            case "intervals":
              values.interval_id = el.id;
              break;

            default:
              break;
          }
        });

        values.level_ids = levelIds;
        values.resource_type_ids = resourceTypeIds;
        values.resource_ids = resourceIds;

        httpServiceAuth
          .post(apiEndpoints.company.countPresets, values)
          .then(() => {
            this.getFilters(this.filterSection);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.$router.push({ name: this.backLinkName });
          });
      } else if (this.filterPage === "occupancy") {
        let levelIds = [];
        let resourceTypeIds = [];
        let resourceIds = [];
        this.selectedFilters.forEach(el => {
          switch (el.slug) {
            case "locations":
              levelIds.push(el.id);
              break;
            case "types":
              resourceTypeIds.push(el.id);
              break;
            case "dates":
              values.period_id = el.id;
              if (el.id === 1) {
                values.date_start = el.date_start;
                values.date_end = el.date_end;
              }
              break;
            case "intervals":
              values.interval_id = el.id;
              break;
            default:
              break;
          }
        });
        values.level_ids = levelIds;
        values.resource_type_ids = resourceTypeIds;
        values.resource_ids = resourceIds;
        httpServiceAuth
          .post(apiEndpoints.company.occupancyPresets, values)
          .then(() => {
            this.getOccupancyFilters(this.filterSection);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.$router.push({ name: this.backLinkName });
          });
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
